import React from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useStaticQuery, graphql } from 'gatsby';
import styles from '../styles/terms.q4a.module.scss';

const Q4ATerms = () => {
  const { allContentfulQ4ATnCs } = useStaticQuery(
    graphql`
    {
      allContentfulQ4ATnCs {
        edges {
          node {
            lastUpdateAt
            content {
              json
            }
          }
        }
      }
    }
    `
  );
  const { node } = allContentfulQ4ATnCs.edges[0];
  return (
    <div className={styles.container}>
      {documentToReactComponents(node.content.json)}
      <div className={styles.lastUpdated}>
        {`Last updated: ${node.lastUpdateAt}`}
      </div>
    </div>
  );
};

export default Q4ATerms;
